<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category Indicator"
              label-for="mc-category-indicator"
            >
              <select
                class="form-control"
                v-model="model.category_indicator_id"
                :disabled="$route.meta.action == 'edit'"
              >
                <option value="">Pilih Kategori</option>
                <option
                  v-for="(i, index) in categories"
                  :key="index"
                  :value="i.id"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Indicator" label-for="mc-indicator">
              <select
                class="form-control"
                v-model="model.indicator_id"
                :disabled="$route.meta.action == 'edit'"
              >
                <option value="">Pilih Indikator</option>
                <option
                  v-for="(i, index) in indicators"
                  :key="index"
                  :value="i.id"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="reset()"
            >
              Reset
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="float-right mr-1"
              @click="filtering()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col cols="12">
      <table-fe
        apiUrl="indicator_data"
        apiBase="/indicator_data"
        creates="true"
        updates="true"
        deletes="true"
        shows="true"
        search="false"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        :sort-by.sync="sortBy"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-fe>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import TableFe from "@/views/table/bs-table/TableFE";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";

export default {
  name: "UsersList",
  components: {
    BRow,
    BCol,
    BCard,
    TableFe,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      config: {
        api: "/indicator_data",
        category: "/category_indicator?orderby=id&sort=desc",
        indicator: "/indicator?orderby=id&sort=desc&category_indicator_id=",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 10,
      sortBy: "id",
      categories: [],
      addctx: null,
      addcall: null,
      indicators: [],
      fields: [
        "no",
        { key: "id", label: "ID", sortable: true },
        { key: "created_at", label: "Tanggal Import", sortable: true },
        { key: "data_periode", label: "Periode Data", sortable: true },
        { key: "data_type", label: "Tipe Data", sortable: true },
        {
          key: "category_indicator.name",
          label: "Category Indicator",
          sortable: true,
        },
        { key: "indicator.name", label: "Indicator", sortable: true },
        { key: "Aksi", label: "Aksi", thStyle: "width:290px" },
      ],
      items: [],
      model: {
        indicator_id: "",
        category_indicator_id: "",
        data_type: "",
      },
    };
  },
  mounted() {
    let _ = this;
    _.getCategory();
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  watch: {
    "model.category_indicator_id": function(newVal, oldVal) {
      let _ = this;
      this.getIndicator(newVal);
    },
  },
  methods: {
    getCategory() {
      let _ = this;
      axios.get(_.config.category).then((response) => {
        let _ = this;
        _.categories = response.data.data.rows;
      });
    },
    filtering() {
      console.log("hallo");
      this.getItems(this.addctx, this.addcall);
    },

    reset() {
      (this.addctx.filter = null),
        (this.model.category_indicator_id = ""),
        (this.model.indicator_id = "");
      this.getItems(this.addctx, this.addcall);
    },
    getIndicator(id) {
      let _ = this;
      axios.get(_.config.indicator + id).then((response) => {
        let _ = this;
        _.indicators = response.data.data.rows;
      });
    },
    getItems(ctx, callback) {
      const _ = this;
      _.addctx = ctx;
      _.addcall = callback;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? "asc" : "desc";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      sortBy = sortBy != "" ? sortBy : "id";
      axios
        .get(
          _.config.api +
            "?per_page=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&orderby=" +
            sortBy +
            "&sort=" +
            orderBy +
            "&category_indicator_id=" +
            this.model.category_indicator_id +
            "&indicator_id=" +
            this.model.indicator_id
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      axios
        .get(_.config.api + "?per_page=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          // _.perPage = 10;
          _.perPage = consume.perPage;
          _.items = consume.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>
